<template>
  <div class="flex flex-col space-y-3">
    <div
      class="flex justify-between items-center sm:flex-col sm:items-start sm:space-y-3"
    >
      <span class="font-main font-extrabold text-xl text-promy-purple-200 "
        >Lots disponibles</span
      >
      <div class="flex text-xs space-x-6 sm:w-full sm:justify-end">
        <div class="flex items-center space-x-2">
          <span class="w-4 h-4 bg-promy-green-50 rounded-full"> </span>
          <span> En vente </span>
        </div>
        <div class="flex items-center space-x-2">
          <span class="w-4 h-4 bg-promy-orange rounded-full"> </span>
          <span> Réserver </span>
        </div>
        <div class="flex items-center space-x-2">
          <span class="w-4 h-4 bg-promy-red rounded-full"> </span>
          <span> Vendu </span>
        </div>
      </div>
    </div>
    <hr />
    <div class="flex flex-col bg-white ">

      <div
      class="grid grid-cols-16 text-xs mb-4w-full lg:grid-cols-4 sm:gap-2 py-3 pr-5 text-promy-gray-650"
      style=" padding-left:64px;"
    >
      
      <span class="col-span-1">Statut </span><span class="lg:hidden">N°lot</span
      ><span class="lg:hidden">Type</span><span class="lg:hidden">Etage</span
      ><span>Surface</span><span class="lg:hidden">Stationnement</span
      ><span class="lg:hidden">Orientation</span
      ><span class="lg:hidden">Espace supp </span><span>Prix total</span
      ><span class="lg:hidden">Montant TVA</span
      ><span class="lg:hidden">Valeur locative</span
      ><span class="lg:hidden">Rentabilité</span
      ><span class="lg:hidden">Valeur locative pinel</span
      ><span class="lg:hidden">Rentabilité pinel</span
      ><span class="lg:hidden">Date livraison</span>
    </div>
    <hr>
    <div class="fex">
      <accordion
        shouldOpenLastItem
        class="w-full rounded-box accordion-ipad mb-20"
      >

        <accordion-item
          v-for="(lot, index) in getLots(programme.lots)"
          :key="index"
          :indice="index"
        >
          <template slot="accordion-trigger">
  
            <a
              class="flex justify-between w-full py-3  cursor-pointer sm:px-3 items-center px-5"
              @click="activingAccordion(index)"
            >
            <div class="flex justify-end">
                  <div
                    class="h-8 w-8 rounded-box flex justify-center items-center mr-3"
                    :class="{
                      'bg-promy-gray-300': active_accordion === index,
                    }"
                  >

                    <img
                      src="/images/up-arrow.svg"
                      v-if="active_accordion === index"
                      class="h-3 w-3 down-arrow rotate-180 transform"
                    />
                    <img
                      src="/images/up-arrow-gray.svg"
                      class="h-3 w-3 up-arrow"
                      v-else
                    />
                  </div>
                </div>
              <div
                class="grid grid-cols-16 text-xs w-full lg:grid-cols-4 sm:gap-2 items-center"
              >
              
                <div class="flex items-center col-span-1">
                  <div
                    :class="`text-${getColorStatut(
                      lot.status
                    )} bg-${getColorStatut(
                      lot.status
                    )} bg-opacity-20 px-4 py-2 rounded-box font-extrabold`"
                  >
                    {{ lot.status }}
                  </div>
                </div>
                <span class="lg:hidden"> {{ lot.information_lot.n_lot }}</span
                ><span class="lg:hidden">{{ lot.type }}</span
                ><span class="lg:hidden">{{ lot.information_lot.etage }}</span
                ><span>{{ lot.surface }} ㎡</span
                ><span class="lg:hidden">{{
                  lot.information_lot.stationnement
                }}</span
                ><span class="lg:hidden">{{
                  lot.information_lot.orientation
                }}</span
                ><span class="lg:hidden"
                  >{{ lot.information_lot.espace_supp }} ㎡</span
                ><span>{{ lot.prix_total | formatEUR }} €</span
                ><span class="lg:hidden">{{
                  lot.information_lot.pourcentage_tva
                }}</span>
                <span
                  class="lg:hidden"
                  v-if="
                    checkLocative(
                      lot.information_lot.valeur_locative_marche
                    ) !== 'NC'
                  "
                >
                  {{ lot.information_lot.valeur_locative_marche | formatEUR }} €
                </span>
                <span
                  class="lg:hidden"
                  v-if="
                    checkLocative(
                      lot.information_lot.valeur_locative_marche
                    ) === 'NC'
                  "
                  >NC</span
                >
                <span class="lg:hidden">
                  {{
                    lot.information_lot.rentabilite_marche | checkRentability
                  }}
                </span>
                <span
                  class="lg:hidden"
                  v-if="
                    checkLocative(
                      lot.information_lot.valeur_locative_marche
                    ) !== 'NC'
                  "
                >
                  {{ lot.information_lot.valeur_locative_pinel | formatEUR }} €
                </span>
                <span
                  class="lg:hidden"
                  v-if="
                    checkLocative(lot.information_lot.valeur_locative_pinel) ===
                    'NC'
                  "
                  >NC</span
                >
                <span class="lg:hidden">
                  {{ lot.information_lot.rentabilite_pinel | checkRentability }}
                </span>
                <span class="lg:hidden">{{
                  lot.information_lot.date_livraison
                }}</span>
             
              </div>
            </a>
          </template>
          <template slot="accordion-content">
            <div class="flex w-full  flex-col">
              <hr class="w-full h-1" />
              <div class="flex flex-col my-4 px-5">
                <div class="flex w-full space-x-8 sm:flex-col sm:space-x-0 ">
                  <div
                    class="flex w-1/4 sm:w-full lg:w-1/2 h-66 flex-col sm-ws:h-45 sm:mb-4"
                  >
                    <SwiperThumbs
                      v-if="lot.documents.images && lot.documents.images.length"
                      class="h-full"
                      :with_base_file="true"
                      :isNavigated="false"
                      :images="lot.documents.images"
                      ref="SwiperThumbs"
                      :with_thumps="false"
                      container_classes="   h-full  "
                      :is_swiper_pagination_web="true"
                      :remove_radius_bottom="true"
                    />

                    <div
                      v-else
                      class="bg-no-repeat bg-cover bg-center rounded-t-box w-full h-full"
                      :style="'background-image: url(/images/default_programme.jpg)'"
                    ></div>

                    <div class="flex w-full">
                      <div
                        class="flex w-1/2 rounded-bl-box bg-promy-purple-100 py-2 px-4 flex-col text-white space-y-1"
                      >
                        <div
                          v-if="lot.information_lot.taux"
                          class="flex flex-col"
                        >
                          <div class="text-xs text-promy-gray-300">
                            Part agence ({{ lot.information_lot.taux }}%)
                          </div>
                          <div class="font-extrabold text-sm">
                            {{
                              ((lot.prix_total * lot.information_lot.taux) /
                                100)
                                | formatEUR
                            }}
                            €
                          </div>
                        </div>
                        <div class="flex flex-col" v-else>
                          <div class="text-xs text-promy-gray-300">
                            Part agence (Forfait)
                          </div>
                          <div class="font-extrabold text-sm">
                            {{ lot.information_lot.forfait | formatEUR }} €
                          </div>
                        </div>
                      </div>

                      <div
                        class="flex w-1/2 rounded-br-box bg-promy-purple-300 py-2 px-4 flex-col text-white space-y-1"
                      >
                        <div class="text-xs text-promy-gray-300">
                          Part Promy (1%)
                        </div>
                        <div class="font-extrabold text-sm">
                          {{ (lot.prix_total / 100) | formatEUR }} €
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex w-3/4 sm:w-full lg:w-1/2">
                    <div
                      class="grid grid-cols-5 text-xs mb-4 w-full sm:grid-cols-2 sm:gap-4 lg:grid-cols-2 gap-3"
                    >
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">N°lot</span>
                        <span class="text-base">{{
                          lot.information_lot.n_lot
                        }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">Type</span>
                        <span class="text-base">{{ lot.type }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">Etage</span>
                        <span class="text-base">{{
                          lot.information_lot.etage
                        }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">Surface</span>
                        <span class="text-base">{{ lot.surface }} ㎡</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">Stationnement</span>
                        <span class="text-base">{{
                          lot.information_lot.stationnement
                        }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500"
                          >Type stationnement
                        </span>
                        <span class="text-base">{{
                          lot.information_lot.type_stationnement
                        }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500"
                          >Prix stationnement
                        </span>
                        <span class="text-base"
                          >{{
                            lot.information_lot.prix_stationnement | formatEUR
                          }}
                          €
                        </span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">Bâtiment </span>
                        <span class="text-base">{{
                          lot.information_lot.batiment
                        }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">Orientation</span>
                        <span class="text-base">{{
                          lot.information_lot.orientation
                        }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">Espace supp</span>
                        <span class="text-base"
                          >{{ lot.information_lot.espace_supp }} ㎡</span
                        >
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">Prix total</span>
                        <span class="text-base"
                          >{{ lot.prix_total | formatEUR }} €</span
                        >
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">Montant TVA</span>
                        <span class="text-base">{{
                          lot.information_lot.pourcentage_tva
                        }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500"
                          >Valeur locative marché</span
                        >
                        <span
                          class="text-base"
                          v-if="
                            checkLocative(
                              lot.information_lot.valeur_locative_marche
                            ) !== 'NC'
                          "
                        >
                          {{
                            lot.information_lot.valeur_locative_marche
                              | formatEUR
                          }}
                          €
                        </span>
                        <span v-else class="text-base">NC</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500"
                          >Rentabilité marché</span
                        >
                        <span class="text-base">
                          {{
                            lot.information_lot.rentabilite_marche
                              | checkRentability
                          }}
                        </span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500"
                          >Valeur locative pinel</span
                        >
                        <span
                          class="text-base"
                          v-if="
                            checkLocative(
                              lot.information_lot.valeur_locative_pinel
                            ) !== 'NC'
                          "
                        >
                          {{
                            lot.information_lot.valeur_locative_pinel
                              | formatEUR
                          }}
                          €
                        </span>
                        <span v-else class="text-base">NC</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500"
                          >Rentabilité pinel</span
                        >
                        <span class="text-base">
                          {{
                            lot.information_lot.rentabilite_pinel
                              | checkRentability
                          }}
                        </span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500"
                          >Cave /cellier n°</span
                        >
                        <span class="text-base">{{
                          lot.information_lot.num_cave_cellier
                        }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500"
                          >Prix cave / cellier</span
                        >
                        <span class="text-base"
                          >{{
                            lot.information_lot.prix_cave_cellier | formatEUR
                          }}
                          €
                        </span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">TVA</span>
                        <span class="text-base">{{
                          lot.information_lot.pourcentage_tva
                        }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-promy-gray-500">Date livraison</span>
                        <span class="text-base">{{
                          lot.information_lot.date_livraison
                        }}</span>
                      </div>

                      <div
                        class="flex flex-col"
                        v-if="
                          lot.documents.brochures &&
                          lot.documents.brochures.length
                        "
                      >
                        <span
                          @click="
                            setModalInfos(
                              'Nom de brochure',
                              'Téléchargement',
                              lot.documents.brochures
                            )
                          "
                          class="text-promy-green-200 font-main font-extrabold cursor-pointer text-base"
                        >
                          Voir les brochures
                        </span>
                      </div>
                      <div
                        class="flex flex-col"
                        v-if="lot.documents.plans && lot.documents.plans.length"
                      >
                        <span
                          @click="
                            setModalInfos(
                              'Nom de plan',
                              'Téléchargement',
                              lot.documents.plans
                            )
                          "
                          class="text-promy-green-200 font-main font-extrabold cursor-pointer text-base"
                        >
                          Voir les plans
                        </span>
                      </div>
                      <div
                        class="flex flex-col"
                        v-if="getVideosVisites(lot).length"
                      >
                        <span
                          @click="
                            setModalInfos(
                              'Lien de video',
                              '',
                              getVideosVisites(lot)
                            )
                          "
                          class="text-promy-green-200 font-main font-extrabold cursor-pointer text-base"
                        >
                          Video Tour
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="flex items-center space-x-4 mt-4"
                  v-if="lot.status === 'En vente' || lot.is_accepted === 1"
                >
                  <button
                    @click="
                      !lot.is_reservation_request_already_made &&
                        reserveLot(lot.uuid)
                    "
                    class="px-6 py-2 uppercase w-fit sm:justify-center sm:w-full"
                    :class="[
                      lot.is_reservation_request_already_made
                        ? lot.is_accepted === 0
                          ? 'btn-red-disabled'
                          : 'btn-purple-disabled'
                        : 'btn-purple',
                    ]"
                  >
                    {{ statutReservation(lot.is_accepted).title }}
                  </button>
                  <span
                    class="text-gray-700 text-sm"
                    v-if="lot.is_reservation_request_already_made"
                  >
                    {{ statutReservation(lot.is_accepted).desc }}
                  </span>
                </div>
              </div>
            </div>
            
          </template>
        </accordion-item>
       
      </accordion>
    </div>
    </div>
   
  </div>
</template>
<script>
export default {
  props: {
    programme: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      status: [
        {
          name: "En vente",
          color: "promy-green-50",
        },
        {
          name: "Réservé",
          color: "promy-orange",
        },
        {
          name: "Vendu",
          color: "promy-red",
        },
      ],
      active_accordion: -1,
      showModalConfirm: false,
      confirm_statut: {
        current_statut: null,
        lot: null,
        index: null,
      },
    };
  },

  filters: {
    checkRentability(value) {
      return (value && typeof value === "number") || value === "NC"
        ? value + "%"
        : "NC";
    },
  },

  methods: {
    statutReservation(is_accepted) {
      switch (is_accepted) {
        case 1:
          return {
            title: "ACCEPTÉ",
            desc: "Votre demande de réservation a été acceptée.",
          };
          break;
        case 0:
          return {
            title: "REFUSÉ",
            desc: "Votre demande de réservation a été refusée",
          };
          break;
        default:
          return {
            title: "Réserver",
            desc: "Votre demande de réservation a été envoyée.",
          };
      }
    },
    checkLocative(value) {
      return (value && typeof value === "number") || value === "NC"
        ? value
        : "NC";
    },
    getLots(lots) {
      let lots_en_vente = lots.filter((lot) => lot.status === "En vente");
      let lots_without_status_en_vente = lots.filter(
        (lot) => lot.status !== "En vente"
      );
      return [...lots_en_vente, ...lots_without_status_en_vente];
    },
    reserveLot(id_lot) {
      this.$http
        .put("reserveByAgency", {
          programme: this.$route.query.programme,
          id_agence: this.$route.query.id_agence,
          token: this.$route.query.token,
          id_lot: id_lot,
        })
        .then((response) => {
          this.$toaster.success("Votre demande de réservation a été envoyée.");
          this.programme.lots = response.data.lots;
        });
    },
    getVideosVisites(lot) {
      return [
        ...(lot.information_lot.videos ? lot.information_lot.videos : []),
        ...(lot.information_lot.visites_virtuelle
          ? lot.information_lot.visites_virtuelle
          : []),
      ];
    },
    setModalInfos(current_title, current_action_title, current_docs) {
      this.$emit("setModalInfos", {
        title: current_title,
        action_title: current_action_title,
        docs: current_docs,
      });
    },
    getColorStatut(statut) {
      switch (statut) {
        case "En vente":
          return "promy-green-50";
        case "Réservé":
          return "promy-orange";
        case "Vendu":
          return "promy-red";
      }
    },
    activingAccordion(idx) {
      this.active_accordion = this.active_accordion === idx ? -1 : idx;
    },
    lotHasAlreadyReservedByAgency(lot) {
      return lot?.reserved_by
        ? lot?.reserved_by.some((el) => el.id === this.$route.query.id_agence)
        : false;
    },
  },
};
</script>
